<template>
  <div>
    <iframe class="pg" :src="url"></iframe>
  </div>
</template>

<script>
  export default {
    name: "billing-pc",
    computed: {
      url() {
        let pg = 'https://plugin.launchpack.co.kr';
        let basket = this.$store.getters.basket;
        let p_noti = {
          buyername: basket.name,
          buyertel: basket.phone,
          buyeremail: basket.email,
          goodname: basket.title,
          price: basket.price
        };

        let url = `${pg}/php/inicis/inicis/pay/lpPayBill.php`;
        url += `?goodname=${basket.title}`;
        url += `&price=${basket.price}`;
        url += `&buyername=${basket.name}`;
        url += `&buyertel=${basket.phone}`;
        url += `&buyeremail=${basket.email}`;
        url += `&p_noti=${p_noti}`;
        url += `&redirect_url=https://launchpack.co.kr/service_account`;
        // let offerPeriod = '';
        // if(basket.option_type===1) {
        //   offerPeriod = 'M1';
        // }
        // url += `&offerPeriod=${offerPeriod}`;

        return url;
      }
    },
  }
</script>

<style lang="stylus" scoped>
  .pg
    position fixed
    z-index 9999
    top 42%
    left 50%
    width 822px
    transform translate(-50%, -50%)
    height 602px
    border 0
</style>